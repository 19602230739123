import React, { useEffect, useState } from "react";
import { StaticImage } from "gatsby-plugin-image";
import {
  Box,
  Flex,
  useMediaQuery,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
} from "@chakra-ui/react";
import Layout2 from "../components/layout-2";
import SEO from "../components/seo";

const CareersPage = () => {
  const heroImageMobileQuery = useMediaQuery("(max-width: 1250px)");
  const [heroImageMobile, setHeroImageMobile] = useState(false);

  //   useEffect(() => {
  //     setHeroImageMobile(heroImageMobileQuery);
  //   }, [heroImageMobileQuery]);

  const schemaMarkup = {
    "@context": "https://schema.org",
    "@type": "Corporation",
    name: "Kaminds Nutrichem Private Limited",
    alternateName: "kaminds",
    url: " https://www.kaminds.com/careers ",
    logo:
      "https://www.kaminds.com/static/d2284c154057c362c68fe045665929d5/63311/Trademark%20Kaminds_RGB%20Logo_Logo_Trademark.png",
    contactPoint: {
      "@type": "ContactPoint",
      telephone: "+917568015258",
      contactType: "technical support",
      areaServed: "IN",
      availableLanguage: ["en", "Hindi"],
    },
    sameAs: [
      "https://www.linkedin.com/company/kaminds",
      " https://www.kaminds.com/careers ",
    ],
  };

  return (
    <Layout2>
      <SEO
        title="Careers"
        description="Our goal is to establish a workplace where people can transform their passion into a career, where they can reach their full potential, love their work, and harness the power of collaboration. Send your application to us at career@kaminds.com."
        keywords="careers, job, Kaminds"
        links={[{ rel: "canonical", href: "https://www.kaminds.com/careers/" }]}
        schemaMarkup={schemaMarkup}
      />
      <Box
        marginTop={["80px", "80px", "80px", "100px", "100px"]}
        position="relative"
      >
        <Box
          display={["block", "none", "none", "none", "none"]}
          position="relative"
        >
          <StaticImage
            src="../images/career-small.jpg"
            // width={800}
            height={1100}
            placeholder="blurred"
            // formats={["AUTO", "WEBP", "AVIF"]}
            objectFit="contain"
            alt="career"
            style={{ display: "block" }}
          />
        </Box>
        <Box
          display={["none", "block", "none", "none", "none"]}
          position="relative"
        >
          <StaticImage
            src="../images/career-medium.jpg"
            width={800}
            height={600}
            placeholder="blurred"
            // formats={["AUTO", "WEBP", "AVIF"]}
            objectFit="contain"
            alt="career"
            style={{ display: "block" }}
          />
        </Box>
        <Box
          display={["none", "none", "block", "none", "none"]}
          position="relative"
        >
          <StaticImage
            src="../images/career.jpg"
            width={1920}
            height={900}
            placeholder="blurred"
            // formats={["AUTO", "WEBP", "AVIF"]}
            objectFit="contain"
            alt="career"
            style={{ display: "block" }}
          />
        </Box>
        <Box
          display={["none", "none", "none", "block", "none"]}
          position="relative"
        >
          <StaticImage
            src="../images/career.jpg"
            width={1920}
            height={800}
            placeholder="blurred"
            // formats={["AUTO", "WEBP", "AVIF"]}
            objectFit="contain"
            alt="career"
            style={{ display: "block" }}
          />
        </Box>
        <Box
          display={["none", "none", "none", "none", "block"]}
          position="relative"
        >
          <StaticImage
            src="../images/career.jpg"
            width={1920}
            height={600}
            placeholder="blurred"
            // formats={["AUTO", "WEBP", "AVIF"]}
            objectFit="contain"
            alt="career"
            style={{ display: "block" }}
          />
        </Box>
        <Box
          margin="auto"
          position="absolute"
          top="50%"
          left="50%"
          transform="translate(-50%, -50%)"
        >
          <Box maxWidth="1300px" width="100%" margin="0 auto">
            <Box
              as="h1"
              fontSize={[
                "70px",
                "70px",
                "70px",
                "95px",
                "100px",
                "110px",
                // "120px",
              ]}
              fontWeight="500"
              color="#fff"
              width="100%"
              fontFamily="Fjalla One !important"
              textAlign="center"
              lineHeight={["75px", "80px", "90px", "105px", "110px", "135px"]}
            >
              Careers at Kaminds
            </Box>
            <Box
              as="p"
              textAlign="center"
              color="#fff"
              fontSize={["25px", "25px", "25px", "30px", "30px"]}
            >
              Together for a better future
            </Box>
          </Box>
        </Box>
      </Box>
      <Box marginTop="80px" padding="2em">
        <Flex
          maxWidth="900px"
          width="100%"
          margin="0 auto"
          background="aliceblue"
          borderRadius="30px"
          padding="2em"
          flexWrap="wrap"
          // flexDirection={["column", "column", "row", "row", "row"]}
        >
          <Box
            position="relative"
            marginLeft={["0px", "0px", "0px", "-70px", "-120px"]}
            width={["100%", "100%", "55%", "45%", "50%"]}
            alignSelf="center"
            borderRadius="30px"
            overflow="hidden"
          >
            <StaticImage
              src="../images/careers-2.jpg"
              placeholder="blurred"
              width={1000}
              // height={1000}
              alt="career"
              style={{ display: "block" }}
            />
            {/* )} */}
          </Box>
          <Box
            marginTop={["30px", "30px", "0px", "0px", "0px"]}
            padding={["0em", "0em", "0em", "2em", "1.3em"]}
            paddingLeft={["0em", "1em", "2em", "2em", "4em"]}
            paddingRight={["0em", "0em", "0em", "0.5em", "0.5em"]}
            width={["100%", "100%", "45%", "60%", "60%"]}
          >
            <Box
              as="p"
              fontSize={["18px", "20px", "20px", "20px", "25px"]}
              color="#1d1a5f"
              lineHeight="38px"
            >
              Our goal is to establish a workplace where people can transform
              their passion into a career, where they can reach their full
              potential, love their work, and harness the power of
              collaboration.
            </Box>
          </Box>
        </Flex>
      </Box>
      <Box padding="2em">
        <Box maxWidth="1000px" margin="auto" textAlign="center" color="#252869">
          <Box as="p" fontSize="25px" marginTop="30px">
            Do you share the same mindset and want to explore your
            entrepreneurial skills with us?
          </Box>
          <Box
            as="h3"
            fontSize="40px"
            fontWeight="500"
            // fontFamily="Fjalla One !important"
            marginTop="40px"
          >
            Come join us!
          </Box>
          <Box as="p" fontSize="25px" marginTop="10px">
            Send your application to us at{" "}
            <a
              href="mailto:careers@kaminds.com"
              style={{ textDecoration: "underline" }}
            >
              careers@kaminds.com
            </a>
            .
          </Box>
        </Box>
      </Box>
      <Box marginTop="30px" padding="2em">
        <Box maxWidth="1000px" margin="auto" background="#ffffff">
          <Box
            as="a"
            href="https://www.ambitionbox.com/overview/kaminds-nutrichem-overview?utm_source=employer-dashboard&utm_campaign=kaminds-nutrichem&utm_medium=badges"
            margin="auto"
            display="block"
            maxWidth="500px"
          >
            <img
              src="https://employer.ambitionbox.com/api/badge/1170361?badge-type=employee-ratings2"
              alt="image"
            />
          </Box>
        </Box>
      </Box>
      {/* <Box marginTop="30px" padding="2em">
        <Box maxWidth="1000px" margin="auto" background="#f4f4f6">
          <Box
            as="h2"
            fontSize="25px"
            textAlign="center"
            color="#252869"
            padding="1em"
          >
            We are constantly on the search for potential talent, check out our
            open positions.
          </Box>
          <Box marginTop="20px" padding="1em">
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Td
                    textAlign="center"
                    fontFamily="Roboto Condensed !important"
                    fontSize="25px"
                    color="#252869"
                  >
                    JOB TITLE
                  </Td>
                  <Td
                    textAlign="center"
                    fontFamily="Roboto Condensed !important"
                    fontSize="25px"
                    color="#252869"
                  >
                    lOCATION
                  </Td>
                  <Td
                    textAlign="center"
                    fontFamily="Roboto Condensed !important"
                    fontSize="25px"
                    color="#252869"
                  >
                    TEAM
                  </Td>
                  <Td
                    textAlign="center"
                    fontFamily="Roboto Condensed !important"
                    fontSize="25px"
                    color="#252869"
                  >
                    APPLY
                  </Td>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td textAlign="center" fontSize="20px" color="#252869">
                    Job 1
                  </Td>
                  <Td textAlign="center" fontSize="20px" color="#252869">
                    Location 1
                  </Td>
                  <Td textAlign="center" fontSize="20px" color="#252869">
                    Team 1
                  </Td>
                  <Td
                    textAlign="center"
                    fontSize="20px"
                    color="#252869"
                    textDecoration="underline"
                  >
                    View Details
                  </Td>
                </Tr>
                <Tr>
                  <Td textAlign="center" fontSize="20px" color="#252869">
                    Job 2
                  </Td>
                  <Td textAlign="center" fontSize="20px" color="#252869">
                    Location 2
                  </Td>
                  <Td textAlign="center" fontSize="20px" color="#252869">
                    Team 2
                  </Td>
                  <Td
                    textAlign="center"
                    fontSize="20px"
                    color="#252869"
                    textDecoration="underline"
                  >
                    View Details
                  </Td>
                </Tr>
                <Tr>
                  <Td textAlign="center" fontSize="20px" color="#252869">
                    Job 3
                  </Td>
                  <Td textAlign="center" fontSize="20px" color="#252869">
                    Location 3
                  </Td>
                  <Td textAlign="center" fontSize="20px" color="#252869">
                    Team 3
                  </Td>
                  <Td
                    textAlign="center"
                    fontSize="20px"
                    color="#252869"
                    textDecoration="underline"
                  >
                    View Details
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </Box>
        </Box>
      </Box> */}
    </Layout2>
  );
};

export default CareersPage;
